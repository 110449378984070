/* CHEATSHEET */
// $bp-sm: 576px;
// $bp-md: 768px;
// $bp-lg: 992px;
// $bp-xl: 1200px;
// $bp-xxl: 1400px;

@use "../00.settings/variables" as v;


@mixin xs-device {
  @media (max-width: (v.$bp-sm - 1)) {
      @content;
  }
}

@mixin sm-device {
  @media (min-width: v.$bp-sm) and (max-width: (v.$bp-md - 1)) {
      @content;
  }
}

@mixin md-device {
  @media (min-width: v.$bp-md) and (max-width: (v.$bp-lg - 1)) {
      @content;
  }
}

@mixin lg-device {
  @media (min-width: v.$bp-lg) and (max-width: (v.$bp-xl - 1)) {
      @content;
  }
}

@mixin xl-device {
  @media (min-width: v.$bp-xl) and (max-width: (v.$bp-xxl - 1)) {
      @content;
  }
}

@mixin xxl-device {
  @media (min-width: v.$bp-xxl) {
      @content;
  }
}

@mixin range-mq ($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
      @content;
  }
}

@mixin min-width ($width) {
  @media (min-width: $width) {
      @content;
  }
}

@mixin max-width ($width) {
  @media (max-width: $width) {
      @content;
  }
}
