$justify: (
    "c" : center,
    "s" : start,
    "e" : end,
    "b" : space-between,
    "a" : space-around,
    "i"  : initial,
);

$justify-keys: map-keys($justify);

$align: (
    "c" : center,
    "s" : flex-start,
    "e" : flex-end,
    "i" : initial,
);

$align-keys: map-keys($align);

@mixin flexin {
    .p-flex {
        @each $a in $align-keys {
            &#{$a} {
                $ver: map-get($align, $a);
                @each $j in $justify-keys {
                    &#{$j} {
                        $hor: map-get($justify, $j);
                        display: flex;
                        align-items: $ver;
                        justify-content: $hor;
                    }
                }
            }
        }
    }
}
@include flexin;

.u-fwrap-wrap {
    flex-wrap: wrap;
}