// Colors
// Megeamos arrays de colores
$colors: map-collect(
  $primary,
  $secondary,
  $state,
  $complementary,
  $semantic,
  $grey,
  $blackwhite
);

@mixin color-modifiers {
  @each $name, $hex in $colors {
    &-#{$name} {
      color: $hex !important;
    }
  }
}

.u-color {
  @include color-modifiers();
}

// Backgrounds

@mixin bg-modifiers {
  @each $name, $hex in $colors {
    &-#{$name} {
      background-color: $hex;
    }
  }
}

.u-bg {
  @include bg-modifiers();
}


// Shadows

@mixin shadow-modifiers {
  @each $name, $rgba in $shadows {
    &-#{$name} {
      box-shadow: $rgba;
    }
  }
}

.u-shadow {
  @include shadow-modifiers();
}



