@font-face {
  font-family: "aig-icons";
  src: url("../assets/fonts/icons/aig-icons.eot");
  src: url("../assets/fonts/icons/aig-icons.eot") format("embedded-opentype"),
    url("../assets/fonts/icons/aig-icons.ttf") format("truetype"),
    url("../assets/fonts/icons/aig-icons.woff") format("woff"),
    url("../assets/fonts/icons/aig-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "aig-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.5;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e900";
}
.icon-checkbox:before {
  content: "\e901";
  
}
.icon-checkbox-checked:before {
  content: "\e902";
}
.icon-chevron-down:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e904";
}
.icon-entry-call:before {
  content: "\e905";
}
.icon-exit-call:before {
  content: "\e906";
}
.icon-info-outline:before {
  content: "\e907";
}
.icon-mobile:before {
  content: "\e908";
}
.icon-open-email:before {
  content: "\e909";
}
.icon-phone:before {
  content: "\e90a";
}
.icon-phone-black-screen:before {
  content: "\e90b";
}
.icon-tick:before {
  content: "\e90c";
}
.icon-trash:before {
  content: "\e90d";
}