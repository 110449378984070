

@mixin face-property($weight, $family: $font-family) {
  font-family: $family;
  font-weight: $weight;
  font-style: 300;
  font-stretch: condensed;
};


@font-face {
  src: (
    url("../../assets/fonts/typo/sourcesanspro-light-webfont.woff2") format('woff2'),
    url("../../assets/fonts/typo/sourcesanspro-light-webfont.woff") format("woff"),
  );
  font-display: swap;

  @include face-property(300);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/sourcesanspro-regular-webfont.woff2") format('woff2'),
    url("../../assets/fonts/typo/sourcesanspro-regular-webfont.woff") format("woff"),
  );
  font-display: swap;

  @include face-property(400);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/sourcesanspro-semibold-webfont.woff2") format('woff2'),
    url("../../assets/fonts/typo/sourcesanspro-semibold-webfont.woff") format("woff"),
  );
  font-display: swap;

  @include face-property(500);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/sourcesanspro-bold-webfont.woff2") format('woff2'),
    url("../../assets/fonts/typo/sourcesanspro-bold-webfont.woff") format("woff"),
  );
  font-display: swap;

  @include face-property(600);
}

/* FONT FAMILY FUTURA */
@font-face {
  src: (
    url("../../assets/fonts/typo/pt-aigfutura-book-webfont.woff2") format('woff2')
  );
  font-display: swap;

  @include face-property(normal, $font-family2);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/pt-aigfutura-medium-webfont.woff2") format('woff2')
  );
  font-display: swap;

  @include face-property(500, $font-family2);
}


@font-face {
  src: (
    url("../../assets/fonts/typo/pt-aigfutura-bold-webfont.woff2") format('woff2')
  );
  font-display: swap;

  @include face-property(600, $font-family2);
}