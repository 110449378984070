@use "sass:math";

// Function to convert pixels to rem
//
// @param $size - Number - The pixels value (just the number) you want to convert to rem 
@function calcrem($size) {
  $rem-size:  math.div($size, 16) + rem;
  @return $rem-size;
}

@mixin setWidthAndHeight($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
  min-height: $height;
}

@mixin flex-align($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin center-contents($align: center, $justify: center) {
  display: flex;
  align-content: $align;
  justify-content: $justify;
}

@mixin clearfix{
  &:after{
    content: "";
    display:inline-block;
    clear:both;
  }
}

@mixin setImage($imagen, $height: 100vh, $position: center center){
  background-image: $imagen;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:$height;
}

@mixin transition($property: all ,$time, $style) {
  transition: $property $time $style;
}

@mixin animation(
  $name,
  $duration: 0s, 
  $timing: '', 
  $delay: '', 
  $count: '', 
  $direction: '', 
  $fill: '', 
  $state: '')
  {
  animation-name: $name;
  animation-duration: $duration;

  @if $timing != '' {
    animation-timing-function: $timing;
  }

  @if $delay != '' {
    animation-delay: $delay;
  }

  @if $count != '' {
    animation-iteration-count: $count;
  }

  @if $direction != '' {
    animation-direction: $direction;
  }

  @if $fill != '' {
    animation-fill-mode: $fill;
  }

  @if $state != '' {
    animation-play-state: $state; 
  }
}

@mixin fixed-full-device($background) {
  background-color: $background;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@mixin sufixes($property, $res) {
  &\@xxl {
    @include xxl-device {
      #{$property}: $res;
    }
  }

  &\@xl {
    @include xl-device {
      #{$property}: $res;
    }
  }

  &\@lg {
    @include lg-device {
      #{$property}: $res;
    }
  }

  &\@md {
    @include md-device {
      #{$property}: $res;
    }
  }

  &\@sm {
    @include sm-device {
      #{$property}: $res;
    }
  }

  &\@xs {
    @include xs-device {
      #{$property}: $res;
    }
  }
}

// mergear arrays
@function map-collect($maps...) {
  $collection: ();

  @each $map in $maps {
    $collection: map-merge($collection, $map);
  }
  @return $collection;
}

@mixin center-absolute {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin absolute-positions($top, $right, $bottom, $left) {
  bottom: $bottom;
  left: $left;
  right: $right;
  top: $top;
}