.e-pill {

  background-color: $c-white;
  border-radius: calcrem(10);
  border-style: solid;
  border-width: calcrem(1);
  font-size: calcrem($f-14);
  padding: calcrem(4) calcrem(8);

  &-primary {
    @extend .e-pill;

    border-color: $c-primary;
    color: $c-primary;

    &--active {
      background-color: $c-comp1;
      border-color: $c-selected1;
      color: $c-selected1;
    }
  }
}