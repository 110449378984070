::ng-deep {
  .cdk-overlay-container,
  .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .cdk-overlay-container {
    position: fixed;
    z-index: 1000;
  }

  .cdk-overlay-pane {
    background-color: $c-white;
    border: calcrem(1) solid $c-grey-2;
    position: absolute;
    margin-top: calcrem(32);
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
}