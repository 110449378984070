/* BLOCK */
.e-btn {

  @include flex-align(center, center);

  border: calcrem(1) solid transparent;
  border-radius: calcrem(4);
  color: $c-white;
  line-height: calcrem(20);
  font-size: calcrem(16);
  opacity: .9;
  padding: calcrem(4.8) calcrem(9.6);

  /*
    STATES
  */
  &:hover {
    color: $c-white;
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }


  /*
    ELEMENTS
  */
  &__icon {
    &--start {
      margin-right: calcrem(10);
    }
    &--end {
      margin-left: calcrem(10);
    }
  }


  /* 
    MODIFIERS
  */
  &--primary {
    @extend .e-btn;

    background-color: $c-primary;
    border-color: $c-primary;

    &:active:not(:disabled) {
      background-color: $c-selected1;
      border-color: $c-selected1;
    }
  }

  &--secondary {
    @extend .e-btn;

    background-color: $c-white;
    border-color: $c-comp3;
    color: $c-primary;

    &:active {
      background-color: $c-selected2;
      color: $c-white;
    }

    &:hover {
      background-color: $c-white;
      color: $c-selected1;
    }
  }

  &--tertiary {
    @extend .e-btn;

    background-color: $c-selected1;
    border-color: $c-selected1;

    &:active {
      background-color: $c-over3;
    }
  }

  &--transparent {
    background-color: transparent;
  }


  &--extrap {
    padding: calcrem(10) calcrem(24);
  }

  &--rounded {
    border-radius: calcrem(100);
  }

  &:disabled {
    opacity: .4;
  }

}