.e-field {
  $self: &;

  display: flex;
  flex-direction: column;
  margin-bottom: calcrem(15);
  position: relative;
  

  &__label {
    color: $c-primary;
    line-height: calcrem(20);
    font-size: calcrem(16);
    font-weight: 400;
    margin-bottom: calcrem(4);
    >span{
      color: $c-warning;
    }
  }

  &__input {
    
    appearance:none;
    background: $c-white;
    background-color: $c-white;
    border: calcrem(1) solid $c-primary-2;
    border-radius: calcrem(4);
    color: $c-secondary;
    font-size: calcrem(16);
    height: calcrem(54);
    padding: calcrem(16) calcrem(8);
    width: 100%;

    &[type="number"], &[type="date"] {
      -moz-appearance   : textfield;
      -webkit-appearance: none;
      background: $c-white;
    background-color: $c-white;

  }
     
    &:focus, 
    &:active, 
    &:focus-visible {
      border-color: $c-primary-3;
      outline: $c-primary-3;
    }

    &:disabled {
      background-color: $c-disabled1;
      border-color: $c-primary-2;
      color: $c-selected2;
    }

    &--select {
      &::before {
        content: "\e903";
        font-family: "aig-icons";
        font-size: calcrem($f-20);
        position: absolute;
        right: calcrem(8);
        top: calcrem(12);
      }
    }
  }

  /* Firefox, ios, edge, opera */
  input[type=number], input[type=date], input[type=text], input[type=email], input[type=tel] {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    font-family: "Source-Sans";
    font-size: calcrem(17.6);
  }

  &__error {
    color: $c-warning;
    font-size: calcrem(12);
  }

  &__pwicon {
    bottom: 0;
    margin: auto 0;
    position: absolute;
    right: calcrem(8);
    top: 0;
  }

  // Material styles

  .mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--focused):not(.mdc-text-field--disabled) {
    .mdc-notched-outline > * {
      border-color: $c-primary-2 !important;
    }
  }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $c-secondary !important;

  }

  .mat-mdc-text-field-wrapper {
    max-height: 56px !important;
  }

}
