/* #region USE */
@use "../00.settings/variables" as v;
@use "../02.tools/functions" as f;
@use "../02.tools/media-queries" as mq;

.c-segment {
    //Props
    --bg-btn-color: transparent;
    --text-btn-color: #{v.$c-primary};

    @include flex-align(center, start);
    background-color: v.$c-white;
    border-color: v.$c-grey-5;
    border-width: f.calcrem(1);
    border-style: solid;
    border-radius: f.calcrem(4);
    overflow: hidden;
    width: 100%;

    &__btn {
        appearance: none;
        background-color: var(--bg-btn-color);
        border-radius: 0;
        border: 0;
        border-right: f.calcrem(1) solid v.$c-grey-5;
        color: var(--text-btn-color);
        flex: 1;
        padding: f.calcrem(6);

        &.active {
            --bg-btn-color: #{v.$c-primary};
            --text-btn-color: #{v.$c-white};
        }
    }
}